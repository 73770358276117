.mainContainer {
  z-index: 5;
  background-color: white;
  position: fixed;
  top: 0;
  left: var(--navbar-width);
  width: calc(100% - var(--navbar-width) - (20px * 2)); /* -padding */
  height: calc(var(--header-height) - (20px * 2));
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.endContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

.burger {
  display: none;
}

@media screen and (max-width: 840px) {
  .mainContainer {
    left: 0;
    width: calc(100% - (20px * 2));
  }

  .envSwitch {
    display: none;
  }
  .burger {
    display: initial;
  }
}
