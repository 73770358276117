.skewedBackground {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    position: relative;
}
.skewedBackground:after {
    --skew-angle: -7.5deg;

    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 45%;
    left: 0;
    background: #FFF;
    transform: skewY(var(--skew-angle));
    -ms-transform: skewY(var(--skew-angle));
    -webkit-transform: skewY(var(--skew-angle));
}

.skewedBackgroundLines {
    position: absolute;
    top: 0;
    right: -120px;
    width: 350px;
    height: 60%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
