.mainContainer {
  padding: 20px;
  padding-top: calc(var(--header-height) + 20px); /* header + padding */
  padding-left: calc(var(--navbar-width) + 20px); /* navbar + padding */
  transition-duration: 200ms;
}

@media screen and (max-width: 840px) {
  .mainContainer {
    padding-left: 20px;
  }
}