.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mainLogoContainer {
    width: 50%;
    height: 100px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}
.title {
    font-size: 1.3em;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: .5em;
}
.subTitle {
    font-size: .8em;
    color: rgba(12, 57, 89, .35);
}

.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin-bottom: 2em;
}
.textFieldLabel {
    color: var(--primary-color);
    font-weight: 500;
    font-size: .9em;
}
.textField {
    width: 100%;
}
.forgotPasswordContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    margin-top: 1em;
}
.forgotPassword {
    color: var(--primary-color);
    font-size: .8em;
    font-weight: 500;
    text-decoration: none;
}
.invalidError {
    color: var(--error-color);
    font-size: .8em;
    font-weight: 500;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--primary-color);
}
.bottomRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
}

.button {
    width: 50%;
    font-size: 1em;
}

.bottomRegister {
    margin-left: 5px;
    color: var(--primary-color);
    font-weight: bold;
}
