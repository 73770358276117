.mainContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.env {
  color:var(--primary-color);
  opacity: .32;
  transition-duration: 100ms;
  cursor: pointer;
}

.activeEnv {
  opacity: 1;
}