.scrollableContainer {
  position: fixed;
  top:0; left:0;

  width: var(--navbar-width);
  height: 100%;

  transition-duration: 300ms;

  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 5;
}
.scrollableContainer::-webkit-scrollbar {
  display: none;
}

.mainContainer {
  width: calc(var(--navbar-width) - (20px * 2)); /* subtract the padding */
  height: calc(100% - (20px * 2));
  min-height: calc(var(--navbar-min-height) - (20px * 2));
  background-color: var(--primary-color);
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logoContainer {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
}

.topHalfContainer {
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbarList {
  width: calc(100% + 20px); /* plus the parent right padding */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
}
.navbarRow {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  color: var(--navbarOption-color);
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding: 5px 0;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  transition-duration: 80ms;
  opacity: .7;
}
.navbarRow:hover {
  opacity: 1;
}
.activeRow {
  background-color: var(--secondary-color);
  opacity: 1;
}

.navbarIcon{
  height: 100%;
  width: 18%;
  display: grid;
  place-items: center;
}
.navbarTitle{
  flex: 70%;
}

.bottomListContainer {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .8em;
  padding-left: 5%;
}
.bottomListItem {
  color: var(--navbarOption-color);
  opacity: .7;
  cursor: pointer;
  transition-duration: 80ms;
  text-decoration: none;

}
.version{
  color: var(--navbarOption-color);
  opacity: .5 ;
  font-size: x-small;

}
.bottomListItem:hover {
  opacity: 1;
}

.envSwitch {
  display: none;
}

@media screen and (max-width: 840px) {
  .scrollableContainer {
    left: -100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3) 266px, transparent);
  }

  .envSwitch {
    display: initial;
  }
}