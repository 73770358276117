.mainContainer {
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
}

.tableContainer {
    min-width: var(--table-min-width);
}

.avatarCell {
    display: flex;
    align-items: center;
    gap: .5em;
}

.statusCell {
    background-color: var(--primary-less-opacity-color);
    padding: 2px 8px;
    color: var(--primary-color);
    border-radius: 16px;
}
