#root {
  width: 100vw; height: 100vh;
}

:root {
  --primary-color: #0C3959;
  --primary-less-opacity-color: rgb(12, 57, 89, .3);
  --secondary-color: #64C4B6;
  --error-color: #FF3333;
  --navbarOption-color: #FCFCFD;

  --navbar-width: 266px;
  --navbar-min-height: 725px;
  --table-min-width: 900px;
  --header-height: 80px
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
