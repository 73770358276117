.topDiv {
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;

}

.title {
 color: var(--primary-color);
 font-size: 1.3em;
 font-weight: 400;
}

.dialogContainer {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: flex-end;
 width: 500px;
 min-height: max-content;
 margin-top: 5px;
 gap: 25px;
}

.textField {
 width: 100%;
}
.statusCell {
    background-color: var(--primary-less-opacity-color);
    padding: 2px 8px;
    color: var(--primary-color);
    border-radius: 16px;
}
@media screen and (max-width: 600px) {
 .dialogContainer {
  width: 250px;
 }
}