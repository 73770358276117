.pageContainer {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.formPageContainer {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer {
    width: 420px;
    height: 300px;
    border-radius: 5px;
    padding: 30px;
    background-color: #fff;
}
