.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mainLogoContainer {
    width: 50%;
    height: 100px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}
.title {
    font-size: 1.3em;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: .5em;
}
.subTitle {
    display: flex;
    text-align: center;
    width: 70%;
    font-size: .8em;
    color: rgba(12, 57, 89, .35);
}

.invalidErrorContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    margin-top: .2em;
}
.invalidError {
    color: var(--error-color);
    font-size: .8em;
    font-weight: 500;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin-bottom: 2em;
}
.textFieldLabel {
    color: var(--primary-color);
    font-weight: 500;
    font-size: .9em;
}
.textField {
    width: 100%;
}

.bottomRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1em;
}

.button {
    width: 50%;
    font-size: 1em;
}
