.topDivContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.topMessage {
    color: var(--primary-color);
    font-size: 1.3em;
    font-weight: 500;
}

.topDivEndContainer {
    display: flex;
    gap: 1em;
}

.detailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--primary-color);
    font-weight: .8em;
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EAECF0;
}
.detailsRow {
    display: flex;
    gap: 5px;
}
.detailsTitle {
    font-weight: 600;
}

.tableInfoContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 10px;
    color: var(--primary-color)
}

@media screen and (max-width: 500px) {
    .topDivContainer {
        flex-direction: column;
        align-items: center;
        gap: 1em;
    }
    .topDivEndContainer {
        width: 100%;
        justify-content: center;
        gap: 1em;
    }
}