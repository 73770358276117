.iconContainer {
    width: 100%; height: 100%;
}

.icon {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
