.topDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.title {
    color: var(--primary-color);
    font-size: 1.3em;
    font-weight: 400;
}

.tableStatus {
    background-color: var(--primary-less-opacity-color);
    padding: 2px 8px;
    color: var(--primary-color);
    border-radius: 16px;
}

.tableTitle {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.actionsCell {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 500px;
    min-height: max-content;
    margin-top: 5px;
    gap: 25px;
}

.dialogMessage {
    align-self: flex-start;
    font-size: medium;
    font-weight: 600;
    color: var(--error-color);
}

.textField {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .dialogContainer {
        width: 250px;
    }
}